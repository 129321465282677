import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getMediaUrl, variousURLs, arcademicsIntroDuration } from "../../config";
import ArcademicsHTML5 from "./arcademicsHTML5";
import { clickToPlayVideoSelector } from "../../redux/slices/preContentVideoSlice";
import ClickToPlayVideo from "../ads/ClickToPlayVideo";
import { useSearchParams } from "react-router-dom";

export default function DesktopContent({ game, isArcademics }) {
  const ifrm = useRef();
  const clickToPlayVideo = useSelector(clickToPlayVideoSelector);
  const [searchParams] = useSearchParams();
  const isInitialLoad = useRef(true);

  const shortnameOverride = searchParams.get("shortname");
  const loadCallback = useCallback(
    (element) => {
      if (element) {
        if (!isArcademics && game?.shortname && !isInitialLoad) {
          ifrm.current.contentWindow.location.replace(
            `${getMediaUrl()}/games/${process.env.NODE_ENV !== "production" && shortnameOverride ? shortnameOverride : game.shortname}/html/index.html`,
          );
        }
        isInitialLoad.current = false;
      }
    },
    [game],
  );

  if (isArcademics) {
    return (
      <ArcademicsHTML5
        gameName={game.arcademicsName}
        settings={{
          introURL: variousURLs.arcademics.intro,
          introDuration: arcademicsIntroDuration,
          endGameEnabled: false,
          printEnabled: false,
        }}
      />
    );
  }

  const gameVisibilityStyle = clickToPlayVideo
    ? {
        opacity: 0,
        visibility: "hidden",
      }
    : null;

  return (
    <>
      {clickToPlayVideo && <ClickToPlayVideo />}

      {game.shortname && (
        <iframe
          id="abcya-game-iframe"
          title={game.shortname}
          style={gameVisibilityStyle}
          frameBorder="0"
          ref={loadCallback}
          allow="accelerometer *;magnetometer *;gyroscope *;"
          src={`${getMediaUrl()}/games/${process.env.NODE_ENV !== "production" && shortnameOverride ? shortnameOverride : game.shortname}/html/index.html`}
        />
      )}
    </>
  );
}

DesktopContent.propTypes = {
  game: PropTypes.object.isRequired,
  isArcademics: PropTypes.bool.isRequired,
};
